import auth from "./api/auth";
import emailQueue from "./api/emailQueueApi";
import dominiApi from "./api/dominiApi";
import dominiActionsApi from "./api/dominiActrionsApi";
import dashboardDataApi from "./api/dashboardDataApi";
import clientiActionsApi from "./api/clientiActionsApi";
import usersActionsApi from "./api/usersActionsApi";


export const apiCalls = {
    auth,
    emailQueue,
    dominiApi,
    dominiActionsApi,
    dashboardDataApi,
    clientiActionsApi,
    usersActionsApi,
};

export default apiCalls;
