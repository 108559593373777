//LOGIN
export const POST_USER_LOGIN = "/api/login";
export const POST_USER_ME = "/api/me";
export const POST_USER_REGISTER = "/api/register";

//EMAIL QUEUE
export let GET_EMAIL_QUEUE = "/api/email-queue";

// API-DOMINI
export const GET_TLD_LIST = "/api/get-list-extension";
export const CHECK_DOMINIO = "/api/domain-check";

// ACTIONS-DOMINI
export const SET_LIST_DOMAINS = "/api/list-domains";
export const GET_DOMAIN_DETAILS=  "/api/details-domain";

// ACTIONS - DASHBOARD
export const SET_OVERVIEW_DOMAINS = "/api/overviewDomain";
export const SET_NOTIFICHE_RINNOVI = "/api/dashboard-scadenza-dominio";
export const SET_NOTIFICHE_RINNOVI_NO_RENEWAL = "/api/dashboard-scadenza-dominio-no-renewal";
export const SET_ANDAMENTO_DOMAINS = "/api/domains/registered";

// ACTIONS-CLIENTI
export const SET_LIST_CLIENTES = "/api/list-contact-name";
export const SET_LIST_CLIENTES_FOR_USER = "/api/list-contact-for-user";

// ACTIONS - UTENTI
export const SET_LIST_UTENTI = "/api/list-users";
export const SET_UTENTE_DETTAGLIO = "/api/user-details";
export const SET_UTENTE_UPDATE = "/api/user-update";
export const SET_UTENTE_DELETE = "/api/user-delete";
export const SET_USER_CREATE = "/api/users-create";

// ACTIONS - CALENDAR
export const DATA_CALENDAR_DOMAINS = "/api/calendar-events";
