import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Input, Button, Spinner } from "reactstrap";
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Search, XCircle, Download, ChevronDown, ChevronUp } from 'lucide-react';
import apiCalls from "../../../contexts/apiCall";
import { exportClients } from './exportUtils';
import ExpandedClientView from './ExpandedClientView';
import { columns, customStyles } from './tableConstants';

const ClientTable = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        name: '',
        company: '',
        email: '',
        phone: ''
    });
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await apiCalls.clientiActionsApi.listaClienti();
            setCustomers(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const filteredCustomers = customers.filter(customer => {
        return (
            customer.contact_name.toLowerCase().includes(filters.name.toLowerCase()) &&
            customer.company.toLowerCase().includes(filters.company.toLowerCase()) &&
            customer.email.toLowerCase().includes(filters.email.toLowerCase()) &&
            customer.voice.toLowerCase().includes(filters.phone.toLowerCase())
        );
    });

    const resetFilters = () => {
        setFilters({
            name: '',
            company: '',
            email: '',
            phone: ''
        });
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };

    const handleRowExpanded = (expanded, row) => {
        setExpandedRow(expanded ? row.id : null);
    };

    const conditionalRowStyles = [
        {
            when: row => row.id === expandedRow,
            style: {
                backgroundColor: '#e6f7ff',
                userSelect: 'none',
            },
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Clienti" breadcrumbItem="Gestione Clienti" />
                    <Row>
                        <Col lg={12}>
                            <Card className="border-0 shadow">
                                <CardBody>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="card-title text-primary mb-0">I tuoi clienti</h4>
                                        <div>
                                            <Button color="primary" size="sm" className="me-2" onClick={() => exportClients(filteredCustomers, 'csv', 'tutti_i_clienti')}>
                                                <Download size={14} className="me-1" /> CSV
                                            </Button>
                                            <Button color="success" size="sm" className="me-2" onClick={() => exportClients(filteredCustomers, 'excel', 'tutti_i_clienti')}>
                                                <Download size={14} className="me-1" /> Excel
                                            </Button>
                                            <Button color="danger" size="sm" onClick={() => exportClients(filteredCustomers, 'pdf', 'tutti_i_clienti')}>
                                                <Download size={14} className="me-1" /> PDF
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                                        <div className="d-flex flex-wrap align-items-center mb-2 mb-md-0">
                                            <Search size={20} className="text-muted me-2" />
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Nome..."
                                                value={filters.name}
                                                onChange={handleFilterChange}
                                                className="form-control-sm me-2 mb-2 mb-md-0"
                                                style={{ width: '150px' }}
                                            />
                                            <Input
                                                type="text"
                                                name="company"
                                                placeholder="Azienda..."
                                                value={filters.company}
                                                onChange={handleFilterChange}
                                                className="form-control-sm me-2 mb-2 mb-md-0"
                                                style={{ width: '150px' }}
                                            />
                                            <Input
                                                type="text"
                                                name="email"
                                                placeholder="Email..."
                                                value={filters.email}
                                                onChange={handleFilterChange}
                                                className="form-control-sm me-2 mb-2 mb-md-0"
                                                style={{ width: '150px' }}
                                            />
                                            <Input
                                                type="text"
                                                name="phone"
                                                placeholder="Telefono..."
                                                value={filters.phone}
                                                onChange={handleFilterChange}
                                                className="form-control-sm mb-2 mb-md-0"
                                                style={{ width: '150px' }}
                                            />
                                        </div>
                                        <Button color="secondary" size="sm" onClick={resetFilters}>
                                            <XCircle size={14} className="me-1" /> Azzera filtri
                                        </Button>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={filteredCustomers}
                                        pagination
                                        expandableRows
                                        expandableRowsComponent={ExpandedClientView}
                                        expandOnRowClicked
                                        onRowExpandToggled={handleRowExpanded}
                                        expandableRowExpanded={row => row.id === expandedRow}
                                        expandableIcon={{
                                            collapsed: <ChevronDown size={20} />,
                                            expanded: <ChevronUp size={20} />
                                        }}
                                        progressPending={loading}
                                        progressComponent={
                                            <div className="text-center my-5">
                                                <Spinner color="primary" />
                                            </div>
                                        }
                                        noDataComponent={
                                            <div className="text-center py-5">
                                                <h4>Nessun cliente trovato</h4>
                                                <p className="text-muted">Prova a modificare i filtri di ricerca</p>
                                            </div>
                                        }
                                        customStyles={customStyles}
                                        conditionalRowStyles={conditionalRowStyles}
                                        highlightOnHover
                                        pointerOnHover
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientTable;
