import {protectedApi} from "../api";
import * as url from "../url_config";

const dashboardDataApi = {
    overviewDomini: async (params = {}) => {
        try {
            const dominioListData = await protectedApi.get(url.SET_OVERVIEW_DOMAINS);
            return dominioListData.data;
        } catch (error) {
            console.error('Failed to fetch domains:', error);
            throw error;
        }
    },
    notificheRinnovi: async (params = {}) => {
        try {
            const notificheRinnoviData = await protectedApi.get(url.SET_NOTIFICHE_RINNOVI);
            return notificheRinnoviData.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    notificheRinnoviNoRenewal: async (params = {}) => {
        try {
            const notificheRinnoviDataNoRenewal = await protectedApi.get(url.SET_NOTIFICHE_RINNOVI_NO_RENEWAL);
            return notificheRinnoviDataNoRenewal.data;
        } catch (error) {
            console.error('Failed to fetch notifiche rinnovi:', error);
            throw error;
        }
    },
    graficoAndamentoDomini: async (viewMode) => {
        try {
            const response = await protectedApi.get(url.SET_ANDAMENTO_DOMAINS, { params: { viewMode } });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch andamento domini:', error);
            throw error;
        }
    }
};

export default dashboardDataApi;
