import { protectedApi } from "../api";
import * as url from "../url_config";
import {SET_UTENTE_DELETE} from "../url_config";

const usersActionsApi = {
    listaUsers: async (params = {}) => {
        try {
            const response = await protectedApi.get(url.SET_LIST_UTENTI, { params });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch users:', error);
            throw error;
        }
    },

    getUserDetails: async (userId) => {
        try {
            const response = await protectedApi.get(`${url.SET_UTENTE_DETTAGLIO}/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            throw error;
        }
    },

    createUser: async (userData) => {
        try {
            const response = await protectedApi.post(url.SET_USER_CREATE, userData);
            return response.data;
        } catch (error) {
            console.error('Failed to create user:', error);
            throw error;
        }
    },

    updateUser: async (userData) => {
        try {
            const response = await protectedApi.put(url.SET_UTENTE_UPDATE, userData);
            return response.data;
        } catch (error) {
            console.error('Failed to update user:', error);
            throw error;
        }
    },

    deleteUser: async (userId) => {
        try {
            const response = await protectedApi.delete(`${url.SET_UTENTE_DELETE}/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Failed to delete user:', error);
            throw error;
        }
    }
};

export default usersActionsApi;
