import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Spinner,
    InputGroup,
    InputGroupText
} from 'reactstrap';
import {X, User, Mail, Key, Shield, ToggleLeft, Send} from 'lucide-react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import apiCalls from "../../contexts/apiCall";

const UserEdit = () => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        role: '',
        password: '',
        changePassword: false,
        is_active: '0',
        invio_email: '0'
    });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const {userId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true);
                const response = await apiCalls.usersActionsApi.getUserDetails(userId);
                setFormData({
                    id: response.user.id || '',
                    name: response.user.name || '',
                    email: response.user.email || '',
                    role: response.user.role || '',
                    password: '',
                    changePassword: false,
                    is_active: response.user.is_active ? '1' : '0',
                    invio_email: '0'
                });
            } catch (err) {
                setError('Errore nel caricamento dell\'utente');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.name) errors.name = 'Il nome è obbligatorio';
        if (!formData.email) errors.email = 'L\'email è obbligatoria';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email non valida';
        if (!formData.role) errors.role = 'Il ruolo è obbligatorio';
        if (formData.changePassword && (!formData.password || formData.password.length < 8)) {
            errors.password = 'La password deve essere di almeno 8 caratteri';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setError(Object.values(errors).join(", "));
            return;
        }

        setSaving(true);
        setError(null);
        try {
            const userData = {...formData};
            if (!userData.changePassword) {
                delete userData.password;
            }
            delete userData.changePassword;

            userData.is_active = parseInt(userData.is_active);
            userData.invio_email = parseInt(userData.invio_email);

            console.log(userData);

            await apiCalls.usersActionsApi.updateUser(userData);
            navigate('/admin/users');
        } catch (err) {
            setError(err.response?.data?.message || 'Si è verificato un errore durante l\'aggiornamento dell\'utente');
        } finally {
            setSaving(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin/users');
    };

    if (loading) return (
        <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <Spinner color="primary"/>
        </div>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Utenti" breadcrumbItem="Modifica Utente"/>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <Card className="shadow">
                                <CardBody className="p-4">
                                    <h4 className="card-title mb-4">Modifica Utente</h4>
                                    {error && (
                                        <Alert color="danger" className="mb-4">
                                            {error}
                                        </Alert>
                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup hidden={true}>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <User size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="id_user"
                                                    name="id_user"
                                                    value={formData.id}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Nome</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <User size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci il nome"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Mail size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci l'email"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="role">Ruolo</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Shield size={18}/>
                                                </InputGroupText>
                                                <Input
                                                    id="role"
                                                    name="role"
                                                    type="select"
                                                    value={formData.role}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Seleziona un ruolo</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="cliente">Cliente</option>
                                                    <option value="user">User</option>
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup check className="mb-3">
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    id="changePassword"
                                                    name="changePassword"
                                                    onChange={handleChange}
                                                />{' '}
                                                Cambia password
                                            </Label>
                                        </FormGroup>
                                        {formData.changePassword && (
                                            <FormGroup>
                                                <Label for="password">Nuova Password</Label>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <Key size={18}/>
                                                    </InputGroupText>
                                                    <Input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        placeholder="Inserisci la nuova password"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        )}
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="is_active">Stato Utente</Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <ToggleLeft size={18}/>
                                                        </InputGroupText>
                                                        <Input
                                                            id="is_active"
                                                            name="is_active"
                                                            type="select"
                                                            value={formData.is_active}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="1">Attivo</option>
                                                            <option value="0">Inattivo</option>
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="invio_email">Invia Email con Credenziali</Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <Send size={18}/>
                                                        </InputGroupText>
                                                        <Input
                                                            id="invio_email"
                                                            name="invio_email"
                                                            type="select"
                                                            value={formData.invio_email}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="1">Sì</option>
                                                            <option value="0">No</option>
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-end mt-4">
                                            <Button type="button" color="light" onClick={handleCancel} className="me-2">
                                                <X size={18} className="me-1"/>
                                                Annulla
                                            </Button>
                                            <Button type="submit" color="primary" disabled={saving}>
                                                {saving ? <Spinner size="sm" className="me-1"/> : null}
                                                Aggiorna Utente
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserEdit;
