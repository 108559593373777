import {protectedApi} from "../api";
import * as url from "../url_config";
import {SET_LIST_CLIENTES_FOR_USER} from "../url_config";

const clientiActionsApi = {

    listaClienti: async (params = {}) => {
        try {
            const clientiListData = await protectedApi.get(url.SET_LIST_CLIENTES);
            return clientiListData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    },
    listlientiForUSer: async (params = {}) => {
        try {
            const clientiListData = await protectedApi.get(url.SET_LIST_CLIENTES_FOR_USER);
            return clientiListData.data;
        } catch (error) {
            console.error('Failed to fetch clients:', error);
            throw error;
        }
    }

};

export default clientiActionsApi;
