import apiCall from "../../contexts/apiCall";

const CalendarioEventi = async () => {
    try {
        const response = await apiCall.dominiActionsApi.getCalendarDataDomains();

        if (response.status === 'success') {
            console.log(response.data.events);
            return response.data.events;
        } else {
            throw new Error('Formato dati non valido');
        }
    } catch (error) {
        console.error('Errore nel recupero delle notifiche di rinnovo:', error);
        return []; // Restituiamo un array vuoto in caso di errore
    }
};

// Esportiamo la funzione direttamente
export default CalendarioEventi;



