import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import apiCalls from "../../../contexts/apiCall";
import DomainTable from './components/DomainTable';

const DomainManagement = () => {
    const [domains, setDomains] = useState([]);
    const [filteredDomains, setFilteredDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchDomains();
    }, []);

    useEffect(() => {
        const filtered = domains.filter(domain =>
            domain.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDomains(filtered);
    }, [searchTerm, domains]);

    const fetchDomains = async () => {
        try {
            const response = await apiCalls.dominiActionsApi.listDomini();
            setDomains(response);
            setFilteredDomains(response);
        } catch (error) {
            console.error('Error fetching domains:', error);
        }
    };

    const handleAction = useCallback((domain, type) => {
        setSelectedDomain(domain);
        setModalType(type);
        setModalOpen(true);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        console.log(`Performing ${modalType} for domain:`, selectedDomain);
        setModalOpen(false);
    }, [modalType, selectedDomain]);

    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }, []);

    const isExpiringWithinTwoMonths = useCallback((expirationDate) => {
        const today = new Date();
        const expDate = new Date(expirationDate);
        const twoMonthsFromNow = new Date(today.setMonth(today.getMonth() + 2));
        return expDate <= twoMonthsFromNow;
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Gestione Domini" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">I tuoi domini</h4>
                                    <DomainTable
                                        domains={filteredDomains}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                        handleAction={handleAction}
                                        formatDate={formatDate}
                                        selectedDomain={selectedDomain}
                                        modalOpen={modalOpen}
                                        modalType={modalType}
                                        setModalOpen={setModalOpen}
                                        handleSubmit={handleSubmit}
                                        isExpiringWithinTwoMonths={isExpiringWithinTwoMonths}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DomainManagement;
