import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Plus } from 'lucide-react';
import apiCalls from "../../../contexts/apiCall";
import UserFilters from './UserFilters';
import UserColumns from './UserColumns';
import ExpandedUserComponent from './ExpandedUserComponent';
import DeleteUserModal from './DeleteUserModal';

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({ name: '', email: '', role: '' });
    const [deleteModal, setDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await apiCalls.usersActionsApi.listaUsers();
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const filteredUsers = users.filter(user => {
        return (
            user.name.toLowerCase().includes(filters.name.toLowerCase()) &&
            user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
            user.role.toLowerCase().includes(filters.role.toLowerCase())
        );
    });

    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await apiCalls.usersActionsApi.deleteUser(userToDelete.id);
            setDeleteModal(false);
            setUserToDelete(null);
            fetchData();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                backgroundColor: '#f8f9fa',
                paddingTop: '12px',
                paddingBottom: '12px',
            },
        },
        rows: {
            style: {
                fontSize: '14px',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#f1f3fa',
                transitionDuration: '0.15s',
                transitionProperty: 'background-color',
                outlineStyle: 'solid',
                outlineWidth: '1px',
                outlineColor: '#e0e0e0',
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Utenti" breadcrumbItem="Gestione Utenti" />
                    <Row>
                        <Col lg={12}>
                            <Card className="border-0 shadow">
                                <CardBody>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="card-title text-primary">Lista Utenti</h4>
                                        <Link to="/admin/users/new" className="btn btn-primary btn-sm">
                                            <Plus size={14} className="me-1" /> Nuovo Utente
                                        </Link>
                                    </div>
                                    <UserFilters filters={filters} setFilters={setFilters} />
                                    <DataTable
                                        columns={UserColumns(handleDeleteClick)}
                                        data={filteredUsers}
                                        pagination
                                        expandableRows
                                        expandableRowsComponent={ExpandedUserComponent}
                                        expandOnRowClicked
                                        progressPending={loading}
                                        progressComponent={<Spinner color="primary" />}
                                        noDataComponent={<div>Nessun utente trovato</div>}
                                        customStyles={customStyles}
                                        highlightOnHover
                                        pointerOnHover
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DeleteUserModal
                isOpen={deleteModal}
                toggle={() => setDeleteModal(false)}
                user={userToDelete}
                onConfirm={handleDeleteConfirm}
            />
        </React.Fragment>
    );
};

export default UsersTable;
