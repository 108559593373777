const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        isHasArrow: true,
        url: "/dashboard"
    },
    {
        label: "Calendario",
        icon: "mdi mdi-calendar",
        isHasArrow: true,
        url: "/calendario"
    },
    {
        label: "Domini",
        icon: "mdi mdi-web",
        subItem: [
            { sublabel: "Lista Domini", link: "/manage-domini", icon: "mdi mdi-dns" },
            { sublabel: "Nuovo dominio", link: "/domini", icon: "mdi mdi-plus-circle-outline" },
        ],
    },
    {
        label: "Clienti",
        icon: "mdi mdi-account-group",
        subItem: [
            { sublabel: "Lista Clienti", link: "/lista-clienti", icon: "mdi mdi-plus-circle-outline" },
            { sublabel: "Nuovo Cliente", link: "/nuovo-cliente", icon: "mdi mdi-dns" },
        ],
        isAdmin: true,
    },
    // Aggiungi il menu Admin visibile solo agli amministratori
    {
        label: "Admin",
        icon: "mdi mdi-shield-account",
        subItem: [
            {
                sublabel: "Lista Utenti",
                link: "/admin/users",
                icon: "mdi mdi-account-multiple"
            },
            {
                sublabel: "Nuovo Utente",
                link: "/admin/users/new",
                icon: "mdi mdi-account-plus"
            },
            // La voce "Modifica Utenti" è stata rimossa perché la modifica
            // sarà accessibile dalla lista utenti
        ],
        isAdmin: true, // Proprietà per identificare che questo menu è solo per admin
    }
];
export default SidebarData;
