import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert, Spinner, InputGroup, InputGroupText
} from 'reactstrap';
import { X, User, Mail, Key, Shield, ToggleLeft, Send, Users, Briefcase, Search } from 'lucide-react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import apiCalls from "../../contexts/apiCall";
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required('Il nome è obbligatorio'),
    email: yup.string().email('Email non valida').required('L\'email è obbligatoria'),
    role: yup.string().required('Ruolo obbligatorio'),
    password: yup.string().min(6, 'Password deve essere almeno 6 caratteri').required('La password è obbligatoria'),
    is_active: yup.string().required('Stato utente obbligatorio'),
    invio_email: yup.string().required('Selezione invio email obbligatoria'),
    client_ids: yup.array().of(yup.number()).when('role', {
        is: 'cliente',
        then: yup.array().min(1, 'Seleziona almeno un profilo').required('Seleziona almeno un profilo'),
        otherwise: yup.array()
    }),
});

const UserCreate = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: '',
        password: '',
        is_active: '1',
        client_ids: [],
        invio_email: '0'
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await apiCalls.clientiActionsApi.listlientiForUSer();
                setClients(response.data);
                setFilteredClients(response.data);
            } catch (err) {
                console.error('Errore nel caricamento dei clienti:', err);
                setError('Errore nel caricamento dei clienti');
            }
        };
        fetchClients();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleClientSelection = useCallback((clientId) => {
        setFormData(prevData => {
            const updatedClientIds = prevData.client_ids.includes(clientId)
                ? prevData.client_ids.filter(id => id !== clientId)
                : [...prevData.client_ids, clientId];
            return { ...prevData, client_ids: updatedClientIds };
        });
    }, []);

    const handleSearch = useCallback((e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = clients.filter(client =>
            client.contact_name.toLowerCase().includes(searchTerm) ||
            (client.company && client.company.toLowerCase().includes(searchTerm))
        );
        setFilteredClients(filtered);
    }, [clients]);

    const columns = useMemo(() => [
        {
            name: 'Seleziona',
            cell: row => (
                <Input
                    type="checkbox"
                    checked={formData.client_ids.includes(row.id)}
                    onChange={() => handleClientSelection(row.id)}
                />
            ),
            width: '100px',
        },
        {
            name: 'Nome',
            selector: row => row.contact_name,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => (row.company && row.company.trim()) || 'N/D',
        },
        {
            name: 'Numero di Domini',
            selector: row => Object.keys(row.admin_domains || {}).length,
            sortable: true,
        },
    ], [formData.client_ids, handleClientSelection]);

    const validateForm = () => {
        try {
            schema.validateSync(formData, { abortEarly: false });
            return {};
        } catch (err) {
            return err.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
            }, {});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setError(Object.values(errors).join(", "));
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const userData = {
                ...formData,
                is_active: parseInt(formData.is_active),
                invio_email: parseInt(formData.invio_email)
            };
            console.log(userData);
            await apiCalls.usersActionsApi.createUser(userData);
            setSubmitStatus({ type: 'success', message: 'Utente creato con successo' });
            setTimeout(() => navigate('/admin/users'), 2000);
        } catch (err) {
            setError(err.response?.data?.message || 'Si è verificato un errore durante la creazione dell\'utente');
            setSubmitStatus({ type: 'danger', message: 'Si è verificato un errore durante la creazione dell\'utente. Riprova più tardi.' });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin/users');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Utenti" breadcrumbItem="Crea Nuovo Utente" />
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <Card className="shadow">
                                <CardBody className="p-4">
                                    <h4 className="card-title mb-4">Inserimento Nuovo Utente</h4>
                                    {error && (
                                        <Alert color="danger" className="mb-4">
                                            {error}
                                        </Alert>
                                    )}
                                    {submitStatus && (
                                        <Alert color={submitStatus.type} className="mb-4">
                                            {submitStatus.message}
                                        </Alert>
                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="name">Nome</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <User size={18} />
                                                </InputGroupText>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci il nome"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Mail size={18} />
                                                </InputGroupText>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci l'email"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="role">Ruolo</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Shield size={18} />
                                                </InputGroupText>
                                                <Input
                                                    id="role"
                                                    name="role"
                                                    type="select"
                                                    value={formData.role}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Seleziona un ruolo</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="cliente">Cliente</option>
                                                    <option value="user">User</option>
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                        { formData.role === 'cliente' && (
                                            <FormGroup>
                                                <Label for="client_ids">Profili</Label>
                                                <InputGroup className="mb-3">
                                                    <InputGroupText>
                                                        <Search size={18} />
                                                    </InputGroupText>
                                                    <Input
                                                        type="text"
                                                        placeholder="Cerca per nome o company"
                                                        value={searchTerm}
                                                        onChange={handleSearch}
                                                    />
                                                </InputGroup>
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredClients}
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="400px"
                                                    highlightOnHover
                                                    pointerOnHover
                                                    noHeader
                                                />
                                            </FormGroup>
                                        )}
                                        <FormGroup>
                                            <Label for="password">Password</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <Key size={18} />
                                                </InputGroupText>
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    placeholder="Inserisci la password"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="is_active">Stato Utente</Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <ToggleLeft size={18} />
                                                        </InputGroupText>
                                                        <Input
                                                            id="is_active"
                                                            name="is_active"
                                                            type="select"
                                                            value={formData.is_active}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="1">Attivo</option>
                                                            <option value="0">Inattivo</option>
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="invio_email">Invia Email con Credenziali</Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <Send size={18} />
                                                        </InputGroupText>
                                                        <Input
                                                            id="invio_email"
                                                            name="invio_email"
                                                            type="select"
                                                            value={formData.invio_email}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="1">Sì</option>
                                                            <option value="0">No</option>
                                                        </Input>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-end mt-4">
                                            <Button type="button" color="light" onClick={handleCancel} className="me-2">
                                                <X size={18} className="me-1" />
                                                Annulla
                                            </Button>
                                            <Button type="submit" color="primary" disabled={loading}>
                                                {loading ? <Spinner size="sm" className="me-1" /> : null}
                                                Crea Utente
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserCreate;
