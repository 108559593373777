import {protectedApi} from "../api";
import * as url from "../url_config";

const dominiActionsApi = {
    listDomini: async (params = {}) => {
        try {
            const dominioListData = await protectedApi.post(url.SET_LIST_DOMAINS);
            return dominioListData.data;
        } catch (error) {
            console.error('Failed to fetch domains:', error);
            throw error;
        }
    },
    getDomainDetails: async (id) => {
        try {
            const domainDetailsData = await protectedApi.post(url.GET_DOMAIN_DETAILS,{id});
            console.log(domainDetailsData);
            return domainDetailsData.data;
        } catch (error) {
            console.error('Failed to fetch domain details:', error);
            throw error;
        }
    },
    getCalendarDataDomains: async () => {
        try {
            const calendarDomainsData = await protectedApi.get(url.DATA_CALENDAR_DOMAINS);

            return calendarDomainsData.data.original;
        } catch (error) {
            console.error('Failed to fetch domain details:', error);
            throw error;
        }
    }


};

export default dominiActionsApi;
