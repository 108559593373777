import React from 'react';
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MoreVertical, Edit, Trash, Mail } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UserColumns = (handleDeleteClick) => {
    const navigate = useNavigate();

    return [
        {
            name: 'Nome',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Ruolo',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Stato',
            selector: row => row.is_active ? 'Attivo' : 'Inattivo',
            sortable: true,
            cell: row => (
                <Badge color={row.is_active ? 'success' : 'warning'} pill className="px-3 py-2">
                    {row.is_active ? 'Attivo' : 'Inattivo'}
                </Badge>
            ),
        },
        {
            name: 'Azioni',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm">
                        <MoreVertical size={16} />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem onClick={() => navigate(`/admin/users/${row.id}/edit`)}>
                            <Edit size={14} className="me-2" /> Modifica
                        </DropdownItem>
                        <DropdownItem href="#"><Mail size={14} className="me-2" /> Invia Email</DropdownItem>
                        <DropdownItem onClick={() => handleDeleteClick(row)} className="text-danger">
                            <Trash size={14} className="me-2" /> Elimina
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        },
    ];
};

export default UserColumns;
