import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import { Search, Globe } from 'lucide-react';
import Select from 'react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import apiCalls from "../../../contexts/apiCall";

const DomainChecker = () => {
    const [domain, setDomain] = useState('');
    const [tld, setTld] = useState(null);
    const [tldList, setTldList] = useState([]);
    const [filteredTldList, setFilteredTldList] = useState([]);
    const [tldInputValue, setTldInputValue] = useState('');
    const [mainResult, setMainResult] = useState(null);
    const [alternativeResults, setAlternativeResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [loadingExtensions, setLoadingExtensions] = useState(false);
    const [extensionError, setExtensionError] = useState('');

    const tldOptions = ['it', 'com', 'org', 'net', 'eu', 'cloud', 'online'];

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '300px',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '300px',
        }),
    };

    useEffect(() => {
        fetchExtensions();
    }, []);



    useEffect(() => {
        filterTldList(tldInputValue);
    }, [tldInputValue, tldList]);

    const fetchExtensions = async () => {
        setLoadingExtensions(true);
        setExtensionError('');
        try {
            const response = await apiCalls.dominiApi.getTldList();
            const options = response.map(ext => ({ value: ext, label: `${ext}` }));
            setTldList(options);
            setFilteredTldList(options);
        } catch (err) {
            console.error('Errore nel caricamento delle estensioni:', err);
            setExtensionError('Errore nel caricamento delle estensioni. Riprova più tardi.');
        } finally {
            setLoadingExtensions(false);
        }
    };

    const filterTldList = (inputValue) => {
        const filtered = tldList.filter(option =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
        );

        filtered.sort((a, b) => {
            const aStartsWith = a.value.toLowerCase().startsWith(inputValue.toLowerCase());
            const bStartsWith = b.value.toLowerCase().startsWith(inputValue.toLowerCase());

            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
            return a.value.localeCompare(b.value);
        });

        setFilteredTldList(filtered);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!tld) {
            setError('Seleziona un\'estensione prima di cercare.');
            return;
        }
        setLoading(true);
        setError('');
        setMainResult(null);
        setAlternativeResults([]);

        try {
            // Prima chiamata per l'estensione principale
            const mainResult = await apiCalls.dominiApi.checkDominio(domain, tld.value);
            const processedMainResult = {
                name: mainResult.domain_check.name,
                available: mainResult.domain_check.avail === "true",
                reason: mainResult.domain_check.reason?.description,
                price: Math.floor(Math.random() * 20) + 5,
                tld: tld.value
            };
            setMainResult(processedMainResult);

            // Chiamate per le estensioni alternative
            const alternativeTlds = tldOptions.filter(option => option !== tld.value);
            const alternativeResults = await Promise.all(
                alternativeTlds.map(option => apiCalls.dominiApi.checkDominio(domain, option))
            );

            const processedAlternativeResults = alternativeResults.map((result, index) => ({
                name: result.domain_check.name,
                available: result.domain_check.avail === "true",
                reason: result.domain_check.reason?.description,
                price: Math.floor(Math.random() * 20) + 5,
                tld: alternativeTlds[index]
            }));

            const sortedAlternativeResults = processedAlternativeResults
                .sort((a, b) => {
                    // Ordina prima per disponibilità
                    if (a.available !== b.available) {
                        return a.available ? -1 : 1;
                    }
                    // Poi per somiglianza con l'estensione principale
                    const aStartsWithMain = a.tld.startsWith(tld.value);
                    const bStartsWithMain = b.tld.startsWith(tld.value);
                    if (aStartsWithMain !== bStartsWithMain) {
                        return aStartsWithMain ? -1 : 1;
                    }
                    // Infine, ordine alfabetico
                    return a.tld.localeCompare(b.tld);
                });

            setAlternativeResults(sortedAlternativeResults);
        } catch (err) {
            setError('Si è verificato un errore durante la verifica del dominio. Riprova più tardi.');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleRegister = (domainName) => {
        console.log(`Registrazione del dominio: ${domainName}`);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Domini" breadcrumbItem="Verifica Disponibilità" />
                    <Row>
                        <Col lg={12}>
                            <Card className="shadow-sm">
                                <CardBody>
                                    <h4 className="card-title mb-4">
                                        <Globe className="me-2" size={24} />
                                        Verifica Disponibilità Dominio
                                    </h4>
                                    <Form onSubmit={handleSearch}>
                                        <Row className="align-items-end">
                                            <Col md={5}>
                                                <FormGroup className="mb-0">
                                                    <Label for="domainName" className="text-muted">Nome del Dominio</Label>
                                                    <Input
                                                        type="text"
                                                        name="domainName"
                                                        id="domainName"
                                                        placeholder="Il tuo dominio"
                                                        value={domain}
                                                        onChange={(e) => setDomain(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup className="mb-0">
                                                    <Label for="tldSelect" className="text-muted">Estensione</Label>
                                                    <Select
                                                        id="tldSelect"
                                                        options={filteredTldList}
                                                        value={tld}
                                                        onChange={(selectedOption) => setTld(selectedOption)}
                                                        onInputChange={(inputValue) => setTldInputValue(inputValue)}
                                                        isSearchable={true}
                                                        isLoading={loadingExtensions}
                                                        placeholder="Seleziona estensione"
                                                        styles={customStyles}
                                                        menuPlacement="auto"
                                                        menuPosition="fixed"
                                                        maxMenuHeight={300}
                                                        noOptionsMessage={() => "Nessuna estensione trovata"}
                                                        loadingMessage={() => "Caricamento estensioni..."}
                                                    />
                                                    {extensionError && <small className="text-danger">{extensionError}</small>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-0">
                                                    <Button color="primary" type="submit" disabled={loading} className="w-100">
                                                        {loading ? 'Verifica in corso...' : <><Search size={18} className="me-2" /> Verifica Disponibilità</>}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>

                                    {error && (
                                        <div className="alert alert-danger mt-4" role="alert">
                                            {error}
                                        </div>
                                    )}

                                    {mainResult && (
                                        <div className="mt-4">
                                            <h5 className="mb-3">Risultato principale</h5>
                                            <Card className="border">
                                                <CardBody>
                                                    <Row className="align-items-center">
                                                        <Col md={6}>
                                                            <h5 className="mb-0">{mainResult.name}</h5>
                                                            {mainResult.available ? (
                                                                <small className="text-success">Disponibile</small>
                                                            ) : (
                                                                <small className="text-danger">Non disponibile</small>
                                                            )}
                                                        </Col>
                                                        <Col md={3} className="text-end">
                                                            {mainResult.available && (
                                                                <span>{mainResult.price.toFixed(2)} €/anno</span>
                                                            )}
                                                        </Col>
                                                        <Col md={3} className="text-end">
                                                            {mainResult.available ? (
                                                                <Button color="success" onClick={() => handleRegister(mainResult.name)}>
                                                                    Registra
                                                                </Button>
                                                            ) : (
                                                                <small className="text-muted">{mainResult.reason}</small>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )}

                                    {alternativeResults.length > 0 && (
                                        <div className="mt-4">
                                            <h5 className="mb-3">Altre estensioni disponibili</h5>
                                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                <Table hover responsive className="mb-0">
                                                    <thead className="table-light">
                                                    <tr>
                                                        <th>Dominio</th>
                                                        <th>Disponibilità</th>
                                                        <th>Prezzo</th>
                                                        <th>Azione</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {alternativeResults.map((result, index) => (
                                                        <tr key={index}>
                                                            <td>{result.name}</td>
                                                            <td>
                                                                {result.available ? (
                                                                    <span className="text-success">Disponibile</span>
                                                                ) : (
                                                                    <span className="text-danger">Non disponibile</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {result.available ? (
                                                                    <span>{result.price.toFixed(2)} €/anno</span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {result.available ? (
                                                                    <Button color="success" size="sm" onClick={() => handleRegister(result.name)}>
                                                                        Registra
                                                                    </Button>
                                                                ) : (
                                                                    <small className="text-muted">{result.reason}</small>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DomainChecker;
