import {protectedApi} from "../api";
import * as url from "../url_config";

const dominiApi = {
    checkDominio: async (domain, tld) => {
        try {
            const dominioData = await protectedApi.post(url.CHECK_DOMINIO, {domain, tld});
            return dominioData.data;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            throw error;
        }
    },
    getTldList: async () => {
        try {
            const tldList = await protectedApi.get(url.GET_TLD_LIST);
            return tldList.data;
        } catch (error) {
            console.error('Failed to fetch TLD list:', error);
            throw error;
        }
    }


};

export default dominiApi;
